
import { defineComponent, ref, onMounted } from "vue";
import moment from "moment-timezone";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import {
  getLogScrapingDetail,
  ILogScrapingDetail,
  LogScrapingDetail,
} from "./logCrawler";
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
  name: "LogUserPage",
  components: {
    Datatable,
  },

  setup() {
    const tableData = ref<Array<ILogScrapingDetail>>(LogScrapingDetail);
    const tableKey = ref(0);
    const total_data = ref(0);
    const is_loading = ref(true);
    const itemsPerPage = ref(10);
    const currentPage = ref(1);
    const search = ref<string>("");
    const router = useRouter();
    const route = useRoute();
    const name_scraper = ref(route.params.name);
    const tableHeader = ref([
      {
        name: "Crawler",
        key: "scraper_name",
        sortable: true,
      },
      {
        name: "Project",
        key: "project",
        sortable: true,
      },
      {
        name: "Topic",
        key: "topic",
        sortable: true,
      },
      {
        name: "Keyword",
        key: "keyword",
        sortable: true,
      },
      {
        name: "Start",
        key: "start",
        sortable: true,
      },
      {
        name: "End",
        key: "end",
        sortable: true,
      },
      {
        name: "Duration",
        key: "duration",
        sortable: true,
      },
      {
        name: "Count Data",
        key: "count",
        sortable: true,
      },
      {
        name: "Status",
        key: "status",
        sortable: true,
      },
      {
        name: "Log",
        key: "log",
        sortable: true,
      },
    ]);

    const fetchData = async () => {
      const payload = {
        scraper_name: name_scraper.value,
        start_date: moment().subtract(6, "days").format("YYYY-MM-DD 00:00:00"),
        end_date: moment().format("YYYY-MM-DD 23:59:59"),
      };
      is_loading.value = true;
      const res = await getLogScrapingDetail(payload);
      tableData.value = res["logs"];
      total_data.value = res["count"];
      is_loading.value = false;
      tableKey.value += 1;
    };

    const formatDate = (date) => {
      return moment.utc(date).format("YYYY-MM-DD HH:mm:ss");
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Scraping Detail", ["Log"]);
      fetchData();
      MenuComponent.reinitialization();
    });

    const currentChange = (current: number) => {
      currentPage.value = current;
      fetchData();
    };

    const itemsPerPageChange = (currentItemsPerPage: number) => {
      itemsPerPage.value = currentItemsPerPage;
      fetchData();
    };

    return {
      tableData,
      total_data,
      tableHeader,
      is_loading,
      currentPage,
      search,
      itemsPerPage,
      currentChange,
      itemsPerPageChange,
      tableKey,
      router,
      route,
      name_scraper,
      formatDate,
    };
  },
});
